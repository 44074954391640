@import "../../../../scss/variables.scss";

.SummaryCard {
  background-color: white;
  border-radius: 5px;
  min-height: 140px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.SummaryCard-good { color: $recon-success; }
.SummaryCard-bad { color: $recon-error; }
.SummaryCard-neutral { color: $recon-secondary; }

.SummaryCard-base-stat-value-text {
  font-weight: normal;
  font-style: normal;
  font-size: 26px;
  line-height: 30px;
  white-space: nowrap;
}

.SummaryCard-clickable:hover {
  cursor: pointer;
}

.SummaryCard-base-stat-value-container {
  text-align: center;
}

.MuiSvgIcon-root.SummaryCard-icon {
  cursor: pointer;
  position: absolute;
  top: 2px;
  right: 2px;
  color: $recon-icon-color;
  font-size: 1.25rem;
}

.SummaryCard-base-stat-label-container {
  text-align: center;
}

.SummaryCard-base-stat-label-text {
  font-weight: 700;
  font-style: normal;
  font-size: 13px;
  letter-spacing: normal;
  text-align: center;
  line-height: normal;
  color: #424242;
}

.SummaryCard-divider {
  margin: 0 10px;
  border-top: 1px dotted;
  color: lightgray;
}

.SummaryCard-goal-container {
  padding: 10px 10px 0 10px;
}

.SummaryCard-goal-label-container {
  float: left;
}

.SummaryCard-goal-label-text {
  font-weight: 400;
  font-style: normal;
  text-align: left;
  font-size: 13px;
  letter-spacing: normal;
  color: #7f7f7f;
}

.SummaryCard-goal-value-container {
  text-align: right;
}

.SummaryCard-goal-value-text {
  font-weight: 650;
  font-style: normal;
  text-align: right;
  font-size: 13px;
  color: #37474f;
}

@media screen and (min-width: 500px) {
  .SummaryCard {
    min-height: 160px;
  }

  .SummaryCard-base-stat-value-text {
    font-weight: normal;
    font-style: normal;
    font-size: 32px;
    line-height: 35px;
  }

  .SummaryCard-base-stat-container {
    padding: 15px;
  }

  .MuiSvgIcon-root.SummaryCard-icon {
    top: 8px;
    right: 8px;
    font-size: 1.5rem !important;
  }
}
