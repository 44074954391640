@import "../../../../scss/theme/variables.scss";
@import "../../../../scss/variables.scss";

.VehicleSearch-container {
  width: 100%;
  margin: 0px 10px;
  border: 2px solid $recon-tertiary;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: $light;
  
  @media screen and (max-width: 1415px) {
    flex: 1 0 100%;
    order: 5;
    margin: 0px;
  }

  .VehicleSearch-bar {
    border: none;
    width: 100%;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.1px;

    &:focus,
    &:active {
      outline: none;
    }
  }

  .VehicleSearch-search-icon {
    font-size: 20px !important;
    fill: $muted !important;
    margin-right: 10px;
  }
}