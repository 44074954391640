@import "../../../../scss/variables.scss";
@import "../../../../scss/theme/variables.scss";

.TextCopy {
  display: flex;
  align-items: center;

  &:hover {
    color: $brandLinkColor;
    cursor: pointer;

    svg {
      path {
        fill-opacity: 1;
        fill: $brandLinkColor;
      }
    }
  }

  svg {
    height: 1em;

    path {
      fill-opacity: 0.6;
      fill: #263238;
    }
  }

  &-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: $spacing-sm;
  }
}
