@import '../../../../scss/variables';

.VehiclePreviewPanel {
  min-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  margin: 0;
  background-color: $recon-white;

  .VehiclePreviewPanel-sold {
    opacity: 0.3;
  }

  .MuiBadge-badge {
    font-weight: bold;
    color: $recon-white;
    background: $recon-primary;
  }

  .VehiclePreviewPanel-arrow {
    padding-bottom: 20px;
    margin-left: -10px;

    .activeArrow {
      opacity: 1;
      cursor: pointer;
      pointer-events: auto;

      &:hover {
        background-color: $recon-hover;
        cursor: pointer;
      }
    }

    .disabledArrow {
      opacity: 0.25;
      cursor: unset;
      pointer-events: none;
    }

    svg {
      margin-right: 10px;
    }
  }

  .VehiclePreviewPanel-info {
    padding: 15px 20px 0;

    .VehiclePreviewPanel-info-row {
      cursor: pointer;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 8px;
      line-height: 1.2;

      .vehicle-type {
        .year-make {
          font-size: 17px;
          font-weight: $recon-medium;
          width: 100%;
        }

        .model {
          font-size: 19px;
          font-weight: $recon-medium;
        }
      }
    }

    .VehiclePreviewPanel-tags-row {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      font-size: 17px;

      .VehiclePreviewPanel-tag {
        margin: 0 10px 10px 0;
        padding: 4px 8px;
        font-weight: $recon-medium;
        background-color: lightgray;
        font-size: 12px;
        border-radius: 6px;
      }

      .VehiclePreviewPanel-tag.tag1 {
        background-color: beige;
        color: #333;
      }

      .VehiclePreviewPanel-tag.tag2 {
        background-color: darkgrey;
        color: $recon-white;
      }

      .VehiclePreviewPanel-tag.tag3 {
        background-color: darkred;
        color: $recon-white;
      }

      .VehiclePreviewPanel-tag.tag4 {
        background-color: indianred;
        color: $recon-white;
      }
    }

    .vin-column {
      margin-right: 1em;
      max-width: 100px;

      @media screen and (min-width: 1200px) {
        max-width: unset;
      }
    }

    .clickable {
      cursor: pointer;

      &:hover {
        color: $brandLinkColor;

        svg {
          path {
            fill-opacity: 1;
            fill: $brandLinkColor;
          }
        }
      }

      svg {
        margin: -4px 0 0 5px;
        height: 1em;
        width: 1em;

        path {
          fill-opacity: 0.6;
          fill: #263238;
        }
      }
    }

    .VehiclePreviewPanel-mileage-row {
      display: inline-flex;
      position: relative;
      top: -7px;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      margin: 0 0 10px;
      max-height: 50px;

      .VehiclePreviewPanel-mileage-title {
        margin: 0;
        padding: 3px 32px 0 0;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0.4px;
        line-height: 1.3;
        text-transform: uppercase;
        color: rgba(38, 50, 56, 0.6);
        white-space: nowrap;
      }

      .VehiclePreviewPanel-mileage-value {
        margin: 0;
        font-size: 16px;
        letter-spacing: 0.3px;
        white-space: nowrap;
      }
    }

    .VehiclePreviewPanel-alerts-row {
      display: inline-block;
      position: relative;
      top: -7px;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      margin: 10px 0;
      max-height: 50px;

      @media screen  and (min-width: 1200px) {
        min-width: 100px;
      }

      .VehiclePreviewPanel-alert {
        display: inline-block;
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: center;
        margin: 0 4px;
        font-size: 10px;
        font-weight: $recon-medium;
        letter-spacing: 0.3;
        cursor: pointer;
      }

      .VehiclePreviewPanel-alert.inactive {
        opacity: 0.6;
        transition: 0.5s opacity;

        &:hover {
          opacity: 1;
        }
      }

      .VehiclePreviewPanel-alerts-loading {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 10;
        transform: translate(-70%, -50%);
      }

      .alert-chip {
        position: relative;
        top: -60px;
        right: -15px;
        font-size: small;
        padding: 3px 4px;
        display: inline;
      }
    }

    .VehiclePreviewPanel-button {
      font-weight: $recon-bold;
      padding: 10px;
      background-color: $recon-secondary;
    }

    .VehiclePreviewPanel-step {
      .VehiclePreviewPanel-step-title {
        color: $recon-step-title-color;
        font-weight: 700;
      }

      .ProgressBar {
        margin-bottom: 1.5rem;
      }

      .StepButtons {
        margin-top: 1.5rem;

        .step-button-base {
          height: 43px !important;
        }
      }
    }

    .VehiclePreviewPanel-recon-complete {
      font-size: x-large;
      margin: 20px 0;
    }

    .TagListSrp {
      max-height: 46px;
      flex-wrap: wrap;
      width: 100%;
      margin-bottom: 5px;

      li {
        margin-bottom: 3px;
      }
    }

    .TagComponent-tag-padding {
      padding: 3px 4px;
    }

    .TagComponent-tag-name {
      font-size: 12px;
      font-weight: 400;
      letter-spacing: 0.4px;
    }

    .SrpTagComponent-tag {
      height: 18px;
      margin: 0 6px 0 0;
    }

    .TagList-truncate-message {
      display: block !important;
      background-color: #263238;
      align-items: center;
      color: #fff;
      border-radius: 6px;
      margin-left: 0;
      margin-right: 0;
      height: 16px !important;
      font-size: 12px !important;
      letter-spacing: 0.4px;
      padding: 0 6px !important;
      line-height: 16px;
    }

    .TagListSrp-icon-button {
      height: 18px;
      font-size: 18px;
    }
  }

  .VehiclePreviewPanel-notes-container {
    height: 250px;
    margin-top: 20px;

    .Notes {
      border-top: 1px solid #ddd;

      .PaneHeader {
        display: none;
      }
    }

    .Notes2 {
      .pane-body {
        padding-bottom: 0 !important;
      }
    }
  }
}
