@import "../../../scss/variables";

.Header {
  height: $header-height;
  z-index: 1500;

  .Header-Navbar {
    background-color: $recon-background-secondary;
    height: $header-height;
    padding: 0 16px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);

    .Header-Toggle-MenuIcon {
      color: $recon-white;
    }

    .Header-Menu-dropdown {
      top: 40px;
    }

    .img-fluid {
      max-height: $header-img-height;
    }

    .navbar-nav {
      height: 100%;

      .nav-link {
        color: $recon-white;
        border-bottom: 0 unset;
        padding: 20px 16px;
        margin: 0;
        height: 100%;
      }

      .nav-link:hover {
        background-color: rgba(251, 251, 251, 0.19);
        border-bottom: 0 unset;
        margin: 0;
      }

      .nav-link.active {
        border-bottom: 4px solid $recon-tab-highlight;
        margin: 0;
      }
    }

    .notifications-link {
      cursor: pointer;
      display: inline-block;
      padding: 0 10px;

      svg {
        font-size: 26px;
      }

      .notifications-icon {
        color: $recon-white;
      }

      .notifications-badge {
        display: inline-block;

        &.nonzero {
          color: rgba(255, 255, 255, 0.7);
        }

        &.open {
          color: #fff;
        }
      }

      .MuiBadge-badge {
        font-weight: bold;
        color: #fff;
        background: $recon-primary;
        border: $recon-background-secondary solid 1px;
      }

      .MuiBadge-anchorOriginTopRightRectangle {
        transform: scale(0.95) translate(45%, -05%);
      }
    }

    .MuiAvatar-root {
      padding: 0 16px;
      border: rgba(251, 251, 251, 0.2) solid 2px;
      background-color: rgba(251, 251, 251, 0.1);// $recon-background-secondary;
    }

    .Header-avatar-dropdown {
      margin-right: 0 !important;
      margin-left: -147px !important;
    }

    .Header-avatar-dropdown-RV-extension-added {
      margin-right: 0 !important;
      margin-left: -147px !important;
    }

    .dropdown-menu.show {
      opacity: 1 !important;
      pointer-events: auto !important;
    }
  }
}

.showroom {
  .Header-Navbar {
    justify-content: center;

    .d-sm-block {
      position: absolute;
      right: 18px;
    }

    .navbar-brand {
      img {
        max-height: 31px;
      }
    }
  }

  .mobileMenu {
    position: absolute;
    left: 22px;
  }
}

.Header-dealership-admin-link {
  padding: 0 10px;
  color: $recon-white;
  cursor: pointer;
}
