@import 'scss/variables';

.SrpTagComponent-tag {
  display: flex;
  margin: 0 0 10px 10px;
  font-weight: normal;
  background-color: lightgray;
  border-radius: 6px;
  height: 32px;
  align-items: center;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
  white-space: nowrap;

  .TagComponent-tag-padding {
    padding: 6px 12px;
    display: flex;

    .TagComponent-tag-icon {
      align-self: center;
      font-size: 15px;
    }

    .TagComponent-tag-name {
      padding: 0 3px;
    }

    .TagComponent-tag-remove-button {
      align-self: center;

      .TagComponent-tag-remove-icon {
        color: rgba(38, 50, 56, 0.38);
        font-size: 12px;
      }
    }
  }
}
