@import 'scss/variables';
@import 'scss/theme/variables';

.VehicleCard-year-make-model {
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .Vehicle-card-year-make {
    font-size: 20px;
    color: $recon-background-secondary;
  }

  .VehicleCard-vin {
    display: flex;
    overflow: hidden;

    .TextCopy {
      overflow: hidden;

      .TextCopy-value {
        direction: rtl;
        text-align: left;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
}
