@import "../../../scss/variables";

.ReportContainer {
  width: 100%;
  padding-bottom: $spacing-slg;
  .ReportContainer-panel-container {
    .ReportContainer-overview {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-self: flex-start;
      .ReportContainer-loadingContainer {
        padding: 150px;
        align-content: center;
        width: 100%;
      }
    }
  }
}
