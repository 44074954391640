@import "../../../../scss/variables";

.DealerStepReport {
  .ReportContainer-overview{
    .card {
      border: 1px solid rgba(0, 0, 0, 0.125);
      border-top: 0;
    }
  }
    
    .card-body {
      padding: 0;
    }
    
    .card-header {
      padding: 0;
      border-bottom: 0;
      margin-bottom: 0;
      background-color: unset;
    }

  .DealerStepReport-title {
    width: 100%;
    font-family: 'OpenSans-Light', 'Open Sans Light', 'Open Sans', sans-serif;
    font-weight: $recon-medium;
    font-style: normal;
    font-size: 20px;
    padding: 18px 8px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .DealerStepReport-SummaryCard-container {
    border: #e0e0e0 solid thin;
    background-color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
    flex-grow: 1;
    margin: 5px;
    max-width: 340px;
    min-width: 140px;

    .SummaryCard-base-stat-value-text {
      font-size: 24px;
    }

    .DealerStepReport-QuadrantCard {
      display: flex;
      flex-wrap: wrap;
      padding: 8px;

      .DealerStepReport-Quadrant {
        padding: 10px 10px;
        width: 50%;
        text-align: center;
        align-content: center;

        h4 {
          margin: 0 0 5px;
        }
        .DealerStepReport-quadrant-good { color: $recon-success; }
        .DealerStepReport-quadrant-bad { color: $recon-error; }
        .DealerStepReport-quadrant-neutral { color: $recon-secondary; }
      }
    }
  }
  .DealerStepReport-StepSummary-summary-row.can-hover {
    &:hover {
      background-color: $recon-hover;
      cursor: pointer;
    }
  }
  .DealerStepReports-StepSummary-summary-row {
    padding: 15px 0 15px 15px;

    .selectable {
      cursor: pointer;
      text-decoration: underline;
    }

    svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeSmall {
      margin-left: -5px;
      margin-right: 5px;
    }

    .border-bottom {
      border-bottom: #f1f1f1 solid 1px;
    }

    .early {
      color: #00c761;
    }

    .overdue {
      color: $recon-danger;
    }
  }

  .DealerStepReports-StepSummary-step {
    font-weight: 500;
    font-size: medium;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
