@import "scss/variables";
@import "scss/theme/variables";

.VehicleCard2 {
  padding: 12px 16px;
  width: 100%;
  margin-bottom: 18px;
  border-radius: 5px !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);

  &:focus {
    border: 1px solid $secondary;
  }

  .VehicleCard-year-make-model {
    margin-left: $spacing-sm;
  }

  .TagComponent-tag {
    margin-right: 4px !important;
    margin-left: 0 !important;
  }

  .TagList-truncate-message {
    display: block !important;
    background-color: #263238;
    align-items: center;
    color: #fff;
    border-radius: 6px;
    margin-right: 2px;
    height: 18px !important;
    font-size: 12px !important;
    letter-spacing: 0.4px;
    padding: 0 6px !important;
    line-height: 16px;
  }

  .caret-show button {
    background: none !important;
  }

  .VehicleCard-model {
    font-size: 16px;
  }

  .VehicleCard-label {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: $primary;
    opacity: 0.7;
    white-space: nowrap;
  }

  .VehicleCard-value {
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 120px;
    overflow: hidden;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: $primary;
    text-transform: capitalize;
  }

  .VehicleCard-value-long {
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: unset;
    width: auto;
    overflow: hidden;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: $primary;
    text-transform: capitalize;
  }

  .VehicleCard-value-vin {
    text-overflow: initial;
    overflow: visible;
  }

  .VehicleCard-progress-row {
    margin-left: -16px;
    margin-right: -16px;
    margin-bottom: 6px;
  }

  .VehicleCard-main-row {
    .col-image {
      flex: 0 0 13.7%;
      max-width: 13.7%;
      display: flex;
      align-items: center;
      justify-content: center;

      .srp-checkbox {
        position: absolute;
        left: 20px;
        top: 0;
      }
    }

    .col-rest {
      flex: 0 0 86.3%;
      max-width: 86.3%;
    }

    .col-details {
      flex: 0 0 35%;
      max-width: 35%;
      min-width: 327px;
    }

    .col-recon {
      flex: 0 0 32.5%;
      max-width: 32.5%;
    }

    .row-details {
      margin-top: 8px;
    }

    .row-nowrap {
      flex-wrap: nowrap !important;
    }

    .col-mi-dlr {
      flex: 0 0 45%;
      max-width: 45%;
    }

    .col-ext-dis {
      flex: 0 0 55%;
      max-width: 55%;
    }

    .col-grow {
      flex-grow: 1;
    }

    .VehicleCard-mode-inventory {
      .col-details {
        flex: 0 0 323px;
        max-width: 323px;
      }

      .col-recon {
        flex: 0 0 302px;
        max-width: 302px;
      }
    }

    .VehicleCard-recon-inventory-tabs-container {
      display: flex;
      border-bottom: 1px solid rgba(38, 50, 56, 0.12);

      .VehicleCard-recon-inventory-tab-label {
        cursor: pointer;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.4px;
        padding-bottom: 3px;
      }

      .VehicleCard-recon-inventory-tab-label.active {
        box-shadow: inset 0 -2px 0 #d32f2f;
      }
    }

    .VehicleCard-recon-inventory-tab-content {
      margin-top: $spacing-md;
      display: flex;
      flex-direction: column;

      .engage {
        height: inherit;
        display: flex;
        flex-direction: column;

        .VehicleCard-label {
          text-transform: uppercase;
        }
      }
    }

    .border-right {
      overflow-y: hidden;
      margin: -15px auto -15px 2px;
      height: 245px;
    }

    .VehicleCard-step-container {
      margin-bottom: 12px;

      .gradient-animated-background {
        width: 230px;
      }
    }

    .assignee-container {
      .gradient-animated-background {
        width: 144px;
      }
    }

    .preownedMargin {
      margin: 3px 16px 7px 0 !important;
    }

    .showroom-right-content {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin: 10px 16px 10px 0;
      justify-content: space-between;

      button {
        margin-left: 15px;
      }
    }

    .showroom-price {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.3px;
    }
  }

  .VehicleCard-details-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    min-width: 0;
  }

  .VehicleCard-tag-actions-container {
    display: flex;
    min-height: 35px;
    max-height: 35px;
    height: 35px;
    justify-content: space-between;

    .StepButtons {
      margin-top: 0;
    }
  }

  .VehicleCard-detail-item {
    display: flex;
    align-items: center;
    margin-right: $spacing-sm;
    margin-bottom: 3px;
    padding-left: inherit;
  }

  .details-table-showroom {
    height: 75px;
    flex-wrap: wrap;
    display: flex;
    flex-direction: column;
  }


  .VehicleCard-recon-table {
    margin-top: 23px;
    min-height: 60px;

    .VehicleCard-detail {
      display: inline-flex;
      align-items: center;
    }

    .VehicleCard-label {
      margin-right: 11px;
      display: inline-block;
    }

    .VehicleCard-value {
      display: inline-block;
    }
  }

  .VehicleCardIcons-container {
    margin: 8px 0;
  }

  .VehicleCard-inventory-duration-container {
    margin-top: 16px;
  }

  .DropdownButton-selectedOption {
    font-size: 14px;
    line-height: 20px;
  }

  .StepDropdown {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .UsersDropdown {
    .dropdown-toggle.btn::after {
      display: inline-block;
      margin-left: 0.255em;
      vertical-align: 0.255em;
      content: "";
      border-top: 0.3em solid !important;
      border-right: 0.3em solid transparent !important;
      border-bottom: 0 !important;
      border-left: 0.3em solid transparent !important;
      color: rgba(0, 0, 0, 0.54);
    }
  }

  .disabled {
    color: rgba(38, 50, 56, 0.12);
  }

  .VehicleCard-tag-row-container {
    margin-top: 9px;
    margin-bottom: 9px;
    min-height: 35px;
    max-height: 35px;

    .TagListSrp {
      width: 100%;
      max-height: 40px;
      flex-wrap: wrap;

      li {
        margin-bottom: 3px;
      }

      .TagComponent-tag-padding {
        padding: 3px 4px;
        display: flex;
      }
    }

    .TagListSrp-icon-button {
      height: 16px;
      margin: 0 4px;
    }
  }

  .VehicleCard-step-buttons-container {
    margin-top: auto;
    display: flex;

    .see-more-button.btn {
      justify-content: flex-start;
    }

    .btn {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 32px;
    }

    .btn + .btn {
      margin-left: $spacing-md;
    }
  }

  .SrpTagComponent-tag {
    height: 18px;
    font-size: 12px;
    letter-spacing: 0.4px;
    line-height: 16px;
    margin: 0 6px 0 0;
  }

  .see-more-button {
    justify-self: start;
    text-align: left;
    font-size: 14px;
    line-height: 17.6px;
    color: $primary;
    font-weight: 700;
    text-transform: uppercase;
    padding-left: 0;
    max-width: 150px;
  }

  .VehicleCard-tabs-row-dropdown-item {
    display: flex;
    align-items: center;

    .VehicleCard-label {
      width: 77px;
      margin-right: 0.5rem;
    }

    @media screen and (min-width: 1000px) {
      .UsersDropdown.Dropdown-btn.dropdown {
        max-width: 300px;
      }
    }

    .UsersDropdown {
      width: 100%;
    }
  }

  .VehicleCard-times-container {
    .VehicleCard-label {
      width: 77px;
    }
  }

  .VehicleCard-tabs-row-inventory-item {
    margin-right: 30px;
  }

  .vehicle-image-count {
    position: absolute;
    background: rgba(38, 50, 56, 0.6);
    border-radius: 4px;
    padding: 3px 8px;
    color: #fff;
    right: 5px;
    bottom: -3px;
    display: flex;
    align-items: center;
    font-size: 10px;

    svg {
      fill: #fff;
      height: 15px;
    }
  }

  .Vehiclecard-showroom {
    flex-wrap: nowrap;

    .col-image {
      flex: 0 0 160px;
      max-width: unset;
    }

    .col-rest {
      flex: 0 0 84.3%;
      max-width: 84.3%;
    }

    .srp-checkbox {
      z-index: 3;

      .MuiSvgIcon-root {
        background-color: rgba(255, 255, 255, 0.8);
        border-radius: 4px;
      }
    }

    .cover-size-image-container {
      position: absolute;
      height: 100%;
      top: -11px;

      .VehicleImageView {
        height: 138px;
        width: 160px;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        overflow: hidden;
        background-size: contain;
      }
    }
  }
}

.engage-buttons {
  svg {
    font-size: 16px;
    margin-left: 5px;
  }
}

.VehicleCardDesktop {
  .Vehicle-card-year-make-model {
    font-size: 20px;
  }

  .VehicleCard-details-table {
    flex-direction: column;

    > .VehicleCard-details-item {
      display: flex;

      .VehicleCard-details-label {
        min-width: 40px;
      }
    }
  }

  .VehicleCardTabFooter {
    margin-top: auto;

    .StepButtons {
      margin: 0;
    }
  }
}

.VehicleCardMobile {
  .VehicleCard-main-row {
    .VehicleCard-main-col {
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      .VehicleCard-details-table {
        margin: $spacing-sm 0;
      }

      .VehicleCard-step-duration-goal {
        display: none;
      }

      .TagListSrp-empty-container {
        min-height: 40px;
      }

      .CheckboxInput {
        position: absolute;
        top: 0;
        right: 15px;
      }

      .no-flex-shrink {
        flex-shrink: 0;
      }

      .horizontal-border {
        border-bottom: rgba(38, 50, 56, 0.12) solid 1px;
      }
    }

    .showRoom-specific {
      justify-content: start;

      .VehicleImageView-size-sm {
        height: 140px;
        width: 100%;
      }
    }

    .CheckboxInput {
      width: 33px;
      margin-left: auto;
    }

    .mobile-showroom-content {
      margin-top: 14px;
    }

    .showroom-bottom-content {
      margin: 0;
      justify-content: space-between;
      width: 100%;

      button {
        width: 48%;
      }
    }

    .mobile-header {
      align-items: center;
    }
  }

  .VehicleCard-recon-inventory-tabs-container {
    display: flex;
    border-bottom: 1px solid rgba(38, 50, 56, 0.12);

    .VehicleCard-recon-inventory-tab-label {
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.4px;
      padding-bottom: 3px;
    }

    .VehicleCard-recon-inventory-tab-label.active {
      box-shadow: inset 0 -2px 0 #d32f2f;
    }
  }

  .VehicleImageView-size-sm {
    width: 60px;
    height: 48px;
  }

  .VehicleImageView-showroom-size {
    width: 160px;
    height: 120px;
  }

  .VehicleCard-tabs-row {
    margin-top: 12px;

    .VehicleCard-tabs-row-dropdown-item {
      width: 154px;
    }

    .VehicleCard-tabs-row-inventory-item {
      width: 80px;
      margin-right: 28px;
    }
  }

  .VehicleCard-progress-row {
    margin-top: 8px;
    height: unset;
    min-height: unset;
    max-height: unset;
    margin-bottom: 10px;

    hr {
      margin-top: 1px;
      margin-bottom: 8px;
    }
  }

  .VehicleCard-recon-inventory-tab-content {
    margin-top: $spacing-md;

    .VehicleCardTabFooter {
      margin-top: auto;

      .StepButtons {
        margin: 0;
      }
    }
  }

  .VehicleCard-recon-table .VehicleCard-label {
    display: block;
    white-space: nowrap;
  }

  .VehicleCard-step-buttons-container {
    display: flex;

    .StepButtons {
      margin: 0;
    }
  }

  .VehicleCard-times-container {
    display: flex !important;
    justify-content: space-between;
    margin-top: 12px;
    overflow-x: hidden;
  }

  .StepButtons {
    .StepButtons-pause-col {
      flex: unset !important;
    }
  }

  .step-button-base {
    min-height: 32px !important;
    max-height: 32px !important;
  }

  .complete-button-base,
  .btn-outline-primary,
  .step-button-base {
    width: 100% !important;
  }

  .StepButtons-pause-col {
    padding-right: 8px;
  }

  .StepButtons-continue-col {
    padding-left: 8px;
  }

  .complete-workflow-button {
    font-size: 13px !important;

    @media (max-width: 340px) {
      font-size: 11px !important;
    }

    svg {
      font-size: 1.3rem !important;

      @media (max-width: 340px) {
        font-size: 1.1rem !important;
      }
    }
  }

  .complete-work-button,
  .pause-button {
    width: 90% !important;
  }

  .SrpTagComponent-tag {
    height: 16px;
    font-size: 12px;
    letter-spacing: 0.4px;
    margin-right: -5px;
  }

  .TagList-truncate-message {
    height: 18px !important;
    font-size: 12px !important;
    letter-spacing: 0.4px;
    padding: 0 4px !important;
  }

  .VehicleCard-label-miles {
    text-transform: uppercase;
  }

  .VehicleCard-tag-row-container-mobile {
    .TagList-Container-mobile {
      margin: 5px 0 1px 0;
    }

    .TagListSrp {
      width: 100%;
      height: 40px;
      flex-wrap: wrap;
      align-items: center;
    }

    .SrpTagComponent-tag {
      margin-right: 5px;
    }
  }

  .tab-buttons-row {
    max-width: unset !important;

    .engage-button-col {
      width: 50%;

      .engage-buttons {
        flex: 1;
        justify-content: center;
      }
    }
  }

  .engage {
    .VehicleCard-step-buttons-container {
      width: 100%;
    }

    @media screen and (min-width: 768px) and (max-width: 1285px),
      (max-width: 565px) {
      .engage-buttons {
        span {
          display: none;
        }
      }
    }
  }

  .view-vehicle {
    max-width: 400px;
    align-self: center;
  }

  .vehicle-image-count {
    bottom: unset;
    right: 15px;
  }

  .showroom-price {
    margin-right: 18px;
  }
}

.VehicleCardExtension {
  .VehicleCard-recon-inventory-tabs-container {
    display: flex;
    border-bottom: 1px solid rgba(38, 50, 56, 0.12);

    .VehicleCard-recon-inventory-tab-label {
      cursor: pointer;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.4px;
      padding-bottom: 3px;
    }

    .VehicleCard-recon-inventory-tab-label.active {
      box-shadow: inset 0 -2px 0 #d32f2f;
    }
  }

  .VehicleImageView-size-sm {
    width: 60px;
    height: 48px;
  }

  .VehicleCard-tabs-engage-link {
    position: fixed;
    right: 18px;

    .VehicleCard-tabs-engage-link-popover {
      position: fixed;
      text-align: center;
      margin-left: -20px;
      margin-top: -100px;
      color: $recon-white;
      border-width: 5px;
      border-style: solid;
      border-color: $recon-secondary;
      border-radius: 5px;
      background-color: $recon-secondary;
    }
  }

  .VehicleCard-tabs-row {
    margin-top: 12px;
    flex-wrap: wrap;

    .VehicleCard-tabs-row-dropdown-item {
      width: 154px;
    }

    .VehicleCard-tabs-row-inventory-item {
      width: 80px;
      margin-right: 28px;
    }
  }

  .VehicleCard-progress-row {
    margin-top: 0.01px;
    height: unset;
    min-height: unset;
    max-height: unset;
    margin-bottom: 10px;

    hr {
      margin-top: 1px;
      margin-bottom: 8px;
    }
  }

  .VehicleCard-recon-inventory-tab-content {
      margin-top: $spacing-md;
  }

  .VehicleCard-recon-table .VehicleCard-label {
    display: block;
    white-space: nowrap;
  }

  .VehicleCard-step-buttons-container {
    margin-top: auto;

    .btn {
      height: 32px;
      padding: 0;
    }
  }

  .VehicleCard-times-container {
    display: flex !important;
    justify-content: space-between;
    margin-top: 12px;
    overflow-x: hidden;
  }

  .StepButtons {
    .StepButtons-pause-col {
      flex: unset !important;
    }
  }

  .step-button-base {
    min-height: 32px !important;
    max-height: 32px !important;
  }

  .complete-button-base,
  .btn-outline-primary,
  .step-button-base {
    width: 100% !important;
  }

  .StepButtons-pause-col {
    padding-right: 8px;
  }

  .StepButtons-continue-col {
    padding-left: 8px;
  }

  .complete-workflow-button {
    font-size: 13px !important;

    @media (max-width: 340px) {
      font-size: 11px !important;
    }

    svg {
      font-size: 1.3rem !important;

      @media (max-width: 340px) {
        font-size: 1.1rem !important;
      }
    }
  }

  .complete-work-button,
  .pause-button {
    width: 90% !important;
  }

  .VehicleCard-step-duration-goal {
    display: none;
  }

  .SrpTagComponent-tag {
    height: 16px;
    font-size: 12px;
    letter-spacing: 0.4px;
    margin-right: -5px;
  }

  .TagList-truncate-message {
    height: 16px !important;
    font-size: 12px !important;
    letter-spacing: 0.4px;
    padding: 0 4px !important;
    margin-left: 8px;
  }

  .VehicleCard-label-miles {
    text-transform: uppercase;
  }

  .VehicleCard-recon-table {
    margin-top: 7px;

    .VehicleCard-detail-item {
      flex-basis: 0;
      flex-grow: 1;
      min-width: 0;
      max-width: 100%;
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}

.showroom-chip {
  background-color: $recon-warning;
  border-radius: 99px;
  color: #fff;
  padding: 3px 8px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
}
