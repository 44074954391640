@import 'scss/variables';

.TagComponent-tag {
  display: flex;
  margin: 0 0 10px 10px;
  font-weight: $recon-medium;
  background-color: lightgray;
  font-size: 12px;
  border-radius: 6px;
  // max-height: 24px;
  height: 100%;
  white-space: nowrap;
  align-items: center;
  justify-content: flex-start;

  .TagComponent-tag-padding {
    padding: 4px;
    display: flex;

    .TagComponent-tag-icon {
      align-self: center;
      font-size: 15px;
    }

    .TagComponent-tag-name {
      padding: 0 3px;
    }

    .TagComponent-tag-remove-button {
      align-self: center;

      .TagComponent-tag-remove-icon {
        color: rgba(38, 50, 56, 0.38);
        font-size: 12px;
      }
    }
  }
}
