@import "scss/variables";
@import "scss/theme/variables";

.VehicleCard-details-table {
  display: flex;
  overflow: hidden;

  .VehicleCard-details-columns {
    display: flex;
    overflow: hidden;

    .VehicleCard-details-columnOne {
      display: flex;
      flex-direction: column;
      min-width: 120px;
      max-width: 150px;
    }

    .VehicleCard-details-columnTwo {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      overflow: hidden;
      margin-left: $spacing-md;
    }

    .VehicleCard-details-item {
      display: flex;

      .VehicleCard-details-label {
        display: inline-block;
        color: $primary;
        font-weight: $recon-medium;
        opacity: 0.7;
        min-width: 40px;
      }

      .VehicleCard-details-value {
        text-transform: capitalize;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        min-width: 80px;
      }

      .VehicleCard-details-value.StockNumber {
        .TextCopy-value {
          direction: rtl;
          text-align: left;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}
