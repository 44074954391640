@import "../../../scss/theme/variables.scss";

.PriceHistoryModal {
  display: flex;
  align-items: center;
  margin-top: 15px;

  .modal-header {
    padding-top: 14px !important;
    padding-bottom: 14px !important;
    box-shadow: inset 0 -1px 0 #e6e7e8;
  }

  .modal-body {
    max-height: 400px;
    overflow-y: auto;
  }

  &-ctrl-btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;

    &-text {
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 18px;
      text-align: center;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: $primary;
      margin-right: 15px;
    }

    &-icon {
      color: $success;
    }
  }

  &-table {
    width: 100%;
  }

  &-header {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 1px;
    text-transform: uppercase;
    height: 50px;
    box-shadow: inset 0 -1px 0 #e6e7e8;

    & > th {
      color: $muted;
    }
  }

  &-table-item {
    height: 50px;
    box-shadow: inset 0 -1px 0 #e6e7e8;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: $primary;
  }

  &-new-price {
    margin-right: 18px;
  }

  &-arrow-icon {
    padding-bottom: 1px;
  }
}
