@import "../../../scss/variables";
@import "../../../scss/theme/variables.scss";

.Reports {
  padding-top: 60px;
  height: 100%;
  .Reports-panel-container {
    background-size: cover;
    background-position: center;

    .Reports-overview {
      max-width: 1440px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-self: flex-start;
      padding-bottom: 25px;
      margin: 10px auto 32px;

      .tab-container {
        margin: $spacing-slg 0 8px !important;
        border-bottom: 1px solid #d2d2d2 !important;
        padding: 0 16px !important;
      }
    }
  }
}
