@import "../../../../../../../scss/variables.scss";
@import "../../../../../../../scss/theme/variables.scss";

.VehicleCardTabFooter-container {
  display: flex;

  .VehicleCardTabFooter-badge {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    opacity: 0.6;
    transition: 0.5s opacity;
    padding: 0;
    margin-right: $spacing-lg;

    .MuiBadge-badge {
      font-weight: bold;
      color: $recon-white;
      background: $recon-primary;
    }

    .VehicleCardTabFooter-badge-icon {
      height: 24px;
    }

    &:hover {
      opacity: 1;
    }
  }

  .VehicleCardTabFooter-Inventory {
    display: flex;
  }

  .VehicleCardTabFooter-children {
    display: flex;
    flex-grow: 1;
    overflow: hidden;

    .VehicleCardTabFooter-Engage-buttons {
      display: flex;

      button {
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        border: none !important;
        color: $recon-secondary;
        opacity: 0.6;
        background-color: $recon-white !important;
        height: unset !important;
        width: unset !important;
        padding: 0;
        line-height: unset !important;
        font-weight: 400 !important;
        margin-right: $spacing-lg;

        svg {
          margin: 0;
          height: 24px;
          width: 24px;
        }

        &:hover {
          opacity: 1;
        }
      }
    }

    .StepButtons {
      display: flex;
      overflow: hidden;
      margin: auto 0 0 auto;

      .StepButtons-pause-col {
        padding-left: 0;
        padding-right: 0;
      }

      .StepButtons-continue-col {
        padding-left: $spacing-md;
        margin-right: 0;
      }

      > button {
        line-height: unset !important;
        display: unset !important;
        min-height: unset !important;
        max-height: unset !important;
        height: unset;
        border: 2px solid #9e9e9e !important;
        background-color: #fff !important;
        color: #9e9e9e !important;
        font-weight: 400 !important;
        margin-left: auto;

        span {
          display: block;
          overflow: hidden;
          text-overflow: ellipsis;
          color: rgba(0, 0, 0, 0.87);
          opacity: .6;
        }
      }
    }
  }
}
