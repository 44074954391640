@import '../../../../scss/variables';

.RepairOrderLine {
  display: flex;
  justify-content: space-between;
  padding: $spacing-sm 28px;
}

.OpCode-and-OpCodeDescription {
  flex: 1;
  display: flex;
  margin-left: $spacing-md;

  .OpCode {
    flex: 1;
  }

  .OpCodeDescription {
    flex: 2;
  }
}

.prices {
  display: flex;
  flex: 1;

  .price {
    flex: 1;
  }
}
