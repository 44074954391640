@import '../../../../scss/variables';
@import '../../../../scss/theme/variables.scss';

$input-padding: 16px 18px 18px 52px;

.mobile-viewport .VehicleSearch2 {
  padding: 30px 16px 0 16px;
  margin-bottom: 16px;
}

.custom-control-input {
  background-color: green !important;
  color: #cc1010;
}

.srp-header-row {
  padding: 5px 14px;
  width: 100%;
  justify-content: space-between;
  font-size: 1.25em;
  align-items: center;

  @media screen and (max-width: 1415px) {
    flex-wrap: wrap;
  }

  .vehicle-count {
    font-weight: $recon-medium;
    color: $recon-secondary;
    white-space: nowrap;
  }

  .showroom-toggle-container {
    .showroom-label {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0.3px;
    }
  }

  .VehicleList-add-vehicle-modal-container {
    position: absolute;
  }

  .VehicleSearch-add-vehicle-button {
    @media screen and (max-width: 1499px) {
      border-radius: 15px;
      padding: 0;
    }

    .add-vehicle-text {
      @media screen and (max-width: 1499px) {
        display: none;
      }
    }

    .add-icon {
      width: 20px;
      height: 18px;

      @media screen and (min-width: 1500px) {
        display: none;
      }
    }
  }

  .hoverHand {
    cursor: pointer;
  }
}

.mobile-viewport .VehicleSearch {
  padding: 0 10px;
}

.srp-header-showroom {
  .header-content {
    margin-right: 0;
  }

  @media screen and (max-width: 1415px) {
    .responsive-col {
      flex-direction: row;
    }

    .Listheader-responsive-right {
      .header-left {
        margin-bottom: 0;
        margin-right: 0;
      }
    }
  }

  .showroom-toggle-container {
    margin-left: 15px;
  }
}

.VehicleListHeader-filter-icon {
  margin: 0 10px;
  fill: rgba(38, 50, 56, 0.6) !important;
  font-size: 20px !important;
  cursor: pointer;

  &-active {
    fill: $danger !important;
  }
}

.VehicleListHeader-sort-dir-icon {
  cursor: pointer;
  fill: $muted !important;

  &-ascending {
    transform: rotate(180deg);
  }
}

.ShowroomEngageInfo {
  .info {
    background-color: $info !important;
  }

  .message {
    line-height: 1.7em;
  }
}
