@import "../../../scss/variables";
@import "../../../scss/theme/variables.scss";

.ReportsDateFilter {
  margin: -54px -16px 0 auto;

  #Reports-filter-toggle {
    padding: 5px 10px;
    text-transform: unset;

    svg.MuiSvgIcon-root {
      font-size: 1.5rem;
      margin: -3px 10px 0 0;
    }
  }

  .filter-select {
    button:focus {
      outline: none;
    }

    margin-top: 0;
  }

  button,
  .button {
    position: relative;
    left: -15px;
    padding: 1px 5px 2px;
    text-transform: unset;
  }

  .btn-light {
    font-weight: $recon-bold;
    color: $primary;
    background-color: $light;
    border-color: $light;
  }

  .btn,
  .btn-light {
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }

  .dropdown-toggle::after {
    vertical-align: 0.2em;
    margin-left: 0.5em;
  }
}
