@import '../../../../../../../scss/variables';

$tag-list-min-height: 25px;

.TagListSrp {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  font-size: 17px;
  width: 82%;

  .TagList-truncate-message {
    cursor: pointer !important;
    padding-left: 8px !important;
    font-size: 14px !important;
    height: 31px !important;
    padding-top: 4px !important;
    padding-right: 8px !important;
    white-space: nowrap !important;
  }

  & li:first-child .SrpTagComponent-tag {
    margin-left: 0;
  }

  .TagListSrp-icon-button {
    height: 16px;
    width: 16px;
    border: none;
    background-color: unset;
    position: relative;
    line-height: 16px;
    padding: 0;
    margin: 0 10px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 1350px) {
      height: 16px !important;
      width: 20px !important;
      background-color: $recon-white;
      margin-top: 0;
      margin-right: 4px;

      .TagListSrp-icon {
        height: 12px !important;
        width: 12px !important;
      }
    }

    .TagListSrp-icon {
      height: 16px !important;
      width: 16px !important;
      border: none;
      font-size: 16px;
      color: rgba(38, 50, 56, 0.48);
      background-color: unset;
    }

    @media screen and (min-width: 1024px) {
      height: 32px;
      font-size: 20px;
      background-color: $recon-white;
    }
  }
}

.TagListSrp-empty-container {
  font-size: 12px;
  color: rgba(38, 50, 56, 0.48);
  vertical-align: bottom;
  background-color: #fff;
  position: relative;
  margin-left: -5px;
  border: none;
  min-width: 75px;
  max-height: 32px;
}

.TagListSrp-empty-container-no-button {
  height: 16px;
}
