@import "../../../scss/variables.scss";
@import "../../../scss/theme/variables.scss";

.LabelValue {
  &-container {
    display: flex;
    font-size: 12px;
    color: $primary;

    &-row {
      flex-direction: row;
      align-items: center;
    }

    &-column {
      flex-direction: column;
      justify-content: center;
    }
  }


  &-label {
    font-weight: $recon-medium;
    opacity: 0.7;
  }

  &-value {
  }
}
