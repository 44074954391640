@import '../../../../scss/variables';

.CorporateReport {
  .card {
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-top: 0;
  }

  .card-body {
    padding: 0;
  }

  .card:not(:first-of-type) {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .card:not(:last-of-type) {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .card-header {
    padding: 0;
    border-bottom: 0;
    margin-bottom: 0;
    background-color: unset;
  }

  .CorporateReport-header {
    display: flex;
    flex-direction: row;
  }

  .CorporateReport-overview {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
  }

  .CorporateReport-title {
    width: 100%;
    font-family: 'OpenSans-Light', 'Open Sans Light', 'Open Sans', sans-serif;
    font-weight: $recon-medium;
    font-style: normal;
    font-size: 20px;
    padding: 18px 8px;
  }

  .CorporateReport-tag-filter {
    margin-left: 15px;
  }

  .CorporateReport-SummaryCard-container {
    border: #e0e0e0 solid thin;
    background-color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
    flex-grow: 1;
    margin: 5px;
    max-width: 340px;
    min-width: 140px;

    .SummaryCard-base-stat-value-text {
      font-size: 24px;
    }

    .CorporateReport-QuadrantCard {
      display: flex;
      flex-wrap: wrap;
      height: 100%;

      .CorporateReport-Quadrant {
        padding: 18px 10px;
        width: 50%;
        text-align: center;
        align-content: center;

        h4 {
          margin: 0 0 5px;
        }

        .quadrant-good {
          color: $recon-success;
        }

        .quadrant-bad {
          color: $recon-error;
        }

        .quadrant-neutral {
          color: $recon-secondary;
        }
      }
    }
  }
}
