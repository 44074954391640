
@import'../../../../../../scss/variables.scss';
@import'../../../../../../scss/theme/variables.scss';

.VehicleCardNotes {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  .VehicleCardNotes-header {
    color: $recon-black;
    font-weight: $recon-medium;
    padding: $spacing-sm 0;
  }

  .VehicleCardNotes-notes {
    display: flex;
    flex-direction: column;
    overflow: hidden auto;
    margin-bottom: auto;

    .VehicleCardNotes-emptyNotes {
      margin: $spacing-xl;
      align-self: center;
    }

    .VehicleCardNotes-note {
      display: flex;
      flex-direction: column;
      padding: $spacing-sm 0;
      margin-right: $spacing-md;

      .VehicleCardNotes-date {
        align-self: center;
        color: $recon-grey;
      }

      .VehicleCardNotes-senderTime {
        display: flex;
        align-items: center;
        margin-top: $spacing-sm;
      }

      .VehicleCardNotes-sender {
        color: $recon-black;
        font-weight: $recon-medium;
      }

      .VehicleCardNotes-time {
        margin-left: $spacing-xl;
        color: $recon-grey;
      }
    }
  }

  .VehicleCardNotes-input {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 $spacing-md;
    border: 2px solid $recon-grey;
    border-radius: 4px;

    div {
      flex: 1;
      margin: 0;
      z-index: 1;

      .DraftEditor-editorContainer {
        padding: 0;
        max-height: 40px;
        overflow: auto;
      }

      .mentionable-input-editor {
        padding: $spacing-md $spacing-sm;
      }
    }

    > button {
      padding: 0;
      margin: 0;
    }

  }
}
