@import 'scss/variables';
@import 'scss/theme/variables';

.VehicleCard-recon-inventory-tabs {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  .VehicleCard-recon-inventory-tabs-container {
    display: flex;
    border-bottom: 1px solid rgba(38, 50, 56, 0.12);

    .VehicleCard-recon-inventory-tab-label {
      cursor: pointer;
      padding-bottom: 3px;
    }

    .VehicleCard-recon-inventory-tab-label.active {
      box-shadow: inset 0 -2px 0 #d32f2f;
    }
  }

  .VehicleCard-recon-inventory-tab-content {
    display: flex;
    flex-grow: 1;
    margin-top: $spacing-sm;

    .VehicleCard-tab-container {
      display: flex;
      flex-direction: column;
      flex-grow: 1;

      .VehicleCard-engage-data {
        &-container {
          display: flex;
          margin-bottom: $spacing-md;
          flex-direction: column;

          .LabelValue-label {
            min-width: 140px;
          }
        }
      }

      .VehicleCardTabFooter-container {
        margin-top: auto;

        .engage-buttons {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .LabelValue-container {
        min-height: 20px;
      }

      .LabelValue-label {
        min-width: 85px;
      }

      .Dropdown-btn.UsersDropdown {
        width: 100%;
      }
    }

    .VehicleCardNotes {
      min-width: unset;
      max-width: unset;
    }
  }
}
